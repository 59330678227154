import React from "react";
import styles from "./Hero.module.scss";
import HeroTitle from "@molecules/HeroTitle/HeroTitle";

const Hero = ({ openChat }) => {
  return (
    <section className={`overflow-hidden text-center mb106 ${styles.section}`}>
      <HeroTitle openChat={openChat} />
    </section>
  );
};

export default Hero;
