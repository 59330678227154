import React from "react";
import styles from "./HeroTitle.module.scss";
import { motion } from "framer-motion";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { navigate } from "gatsby";

const initial = { opacity: 0, y: 20 };

const animateP = { opacity: 0.8, y: 0 };

const HeroTitle = ({ openChat }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoint();
  return (
    <div className='row'>
      <div className='col-md-6 m-auto'>
        <div>
          <motion.h1
            initial={initial}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 20 }}
            className={styles.heroH1}
          >
            {t("HERO.TITLE")}{" "}
            {breakpoints.sm && (
              <span className='colorGradient'>{t("HERO.HIGHLIGHT")}</span>
            )}
          </motion.h1>
          {!breakpoints.sm && (
            <motion.h1
              style={{ marginTop: 0, fontWeight: 500 }}
              initial={initial}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: "spring",
                stiffness: 20,                
              }}
              className='colorGradient'
            >
              {t("HERO.HIGHLIGHT")}
            </motion.h1>
          )}
        </div>
        <motion.p
          initial={initial}
          animate={animateP}
          className={`${styles.heroSub}`}
          transition={{
            type: "spring",
            stiffness: 20,            
          }}
        >
          {t("HERO.SUBTITLE")}
        </motion.p>
        <motion.div
          initial={initial}
          animate={animateP}
          transition={{
            type: "spring",
            stiffness: 20,            
          }}
        >
          <button className={styles.btnHero} onClick={() => navigate("/platform")}>
            {t("HERO.BUTTON")}
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroTitle;
