import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import SEO from "../components/Seo";
import "@styles/index.scss";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";
import ScrollTopButton from "@molecules/ScrollTopButton";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { isBrowser } from "@utils/isBrowser";
import FloatingSeeDemo from "../components/molecules/FloatingSeeDemo";
import Hero from "@organism/Hero/Hero";
import PipeDriveChat from "@utils/pipedriveChat";

const Chart3d = loadable(() => import("@organism/Chart/Chart3d"));
const N5Impact = loadable(() => import("@organism/N5Impact/N5Impact"));
const Testimonies = loadable(() => import("@organism/Testimonies/Testimonies"));
const Partners = loadable(() => import("@organism/Partners/Partners"));
const Awards = loadable(() => import("@organism/Awards/Awards"));
const BgLayout = loadable(() => import("@atoms/BgLayout"));
const Brands = loadable(() => import("@molecules/Brands"));
const FinancialIndustry = loadable(() =>
  import("@organism/FinancialIndustry/FinancialIndustry")
);

const IndexPage = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  // const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
  // {
  //   color: #FFFFFF !important;
  //    -webkit-text-fill-color: #FFFFFF !important;
  // }`);
  const [chatStyle, setChatStyle] = useState(`
          html body #LeadboosterContainer.proactiveChat, html body #LeadboosterContainer
          {
            bottom: -1rem!important;
            right: -1rem!important;
          }
          
          #LeadboosterContainer button[data-testid="chat-bubble"]{
            border-radius: 100%!important;
            transform: scale(0.65)!important;
          }
  `);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);
  const [configChat, setConfigChat] = useState(false);

  const handleOnLoad = () => {
    if (isBrowser) {
      if (window.screen.width > 800) {
        // setTimeout(() => {
        //   setChatStyle(`textarea[class^="inputField_"]
        //   {
        //     color: #FFFFFF !important;
        //      -webkit-text-fill-color: #FFFFFF !important;
        //   } `);
        // }, 2500);
        setTimeout(() => {
          setChatStyle(`
          #LeadboosterContainer.proactiveChat, #LeadboosterContainer
          {
            bottom: -1rem!important;
            right: -1rem!important;
          }

          #LeadboosterContainer button[data-testid="chat-bubble"]{
            border-radius: 100%!important;
            transform: scale(0.65)!important;
          }
          `);
        }, 2500);
      }
    }
  };
  const openChat = () => {
    setinitChat(true);

    const configChatScript =
      "window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 7411403,playbookUuid: '24208787-d7b3-4340-8733-0acc74492a30',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();";
    const pipedriveScript =
      "https://leadbooster-chat.pipedrive.com/assets/loader.js";

    // setClientChat(t("PIPEDRIVE"));
    // setClientChat(t("JIVOCHAT"));

    setConfigChat(configChatScript);
    setClientChat(pipedriveScript);

    handleOnLoad();
  };

  useEffect(() => {
    // if (initChat) {
    //   if (isBrowser && clientChat !== false && clientChat !== t("JIVOCHAT")) {
    //   if (isBrowser && clientChat !== false) {
    //     window.location.reload();
    //   }
    // }
  });

  return (
    <>
      <SEO
        title={t("SEO.HOME.TITLE")}
        description={t("SEO.HOME.DESCRIPTION")}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <Navbar location={location} />
      <Hero openChat={openChat} />
      <Chart3d />
      <N5Impact />
      <FinancialIndustry language={language} openChat={openChat} />
      <Partners />
      <BgLayout>
        <Awards />
        <Testimonies home location={location} />
      </BgLayout>
      <Brands showTitle gradients isHome />
      {/* <Chat
        chatStyle={chatStyle}
        clientChat={clientChat}
        configChat={configChat}
        initChat={initChat}
        openChat={openChat}
      /> */}
      <PipeDriveChat />
      <FloatingSeeDemo />
      <ScrollTopButton />
      <Footer openChat={openChat} />
    </>
  );
};

export default IndexPage;
